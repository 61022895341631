import React, { Component } from "react";

class BarBottomBorder extends Component {
    render() {
        return (
            <div className="barBottomBorderId">
                
            </div>
        );
    }
}

export default BarBottomBorder;