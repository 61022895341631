import React, { Component } from "react";

class NameBar extends Component {
    render() {
        return (
            <div className="nameBarFlex">
                <h1>charlie vinson</h1>
            </div>
        );
    }
}

export default NameBar;