import React, { Component } from "react";

class BrickSmashContainer extends Component {
    render() {
        return (
            <div className="brickSmashContainer">
                <div className="brickSmash">
                    <a href="http://www.github.com" target="_blank"><img src="BrickSmash5.png" alt="picture"></img></a>
                </div>
            </div>
        );
    }
}

export default BrickSmashContainer;